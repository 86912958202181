<template>
  <div>
    <!-- 搜索框-->
    <van-search v-model="value" placeholder="请输入搜索关键词"/>
    <van-row>
      <van-col span="14" :style="{fontSize:'15px'}">当前查询数据条数：{{ listRow }}条</van-col>
    </van-row>
    <!-- 列表-->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
        <div v-if="list.length<=0">
          <van-empty image="error" description="没有数据"/>
        </div>
        <div v-else>
          <van-list>
            <CustomerEj-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
          </van-list>
        </div>
      </div>
    </van-pull-refresh>
    <!-- 添加按钮-->
    <!--    <div style="position: absolute; top: 480px; right: 10px" @click="addMailInfo()">-->
    <!--      <circle-menu btn type="middle" :number="4" circle>-->
    <!--      </circle-menu>-->
    <!--    </div>-->
    <!--    <Add to="/FirstAddressContact"></Add>-->
    <!-- 底部导航栏-->
    <crm-tab-bar/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import CustomerEjCell from '@/components/CustomerEjCell'
import Add from '@/components/Add';

export default {
  name: "CustomerEjList",
  components: {CrmTabBar, CustomerEjCell, Add},
  data() {
    return {
      //查询条数
      listRow: '',
      colors: '',
      list: [],
      value: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉标识
      loading: false,
      //上拉加载完成
      finished: false,
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - this.$refs.fresh.getBoundingClientRect().top - 50
    this.onRefresh();
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getOSCompanyInfo.json',
          {crmCustomerid: this.$route.params.name}).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          this.list = res.data
          this.listRow = res.data.length > 0 ? res.data.length : 0
          for(let i=0; i<this.list.length; i++){
            this.list[i].xh = i+1
            this.list[i].detail = '查看详情'
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    // 上拉加载
    onLoad() {
      // 加载状态结束
      this.loading = false;
    },
  }
}
</script>

<style scoped>

</style>
